<template>
  <div v-if="visible" class="transitions fixed bottom-70px right-26px md:right-51px h-40px w-40px rounded-full flex-center bg-primary/80 cp hover:bg-primary z-98" @click="handleGoTop">
    <svg-icon name="jump" size="17" class="c-#fff rotate--90"/>
  </div>
</template>

<script setup lang="ts">
	const visible = ref(false);

	const handleGoTop = () => {
		if (typeof window !== 'undefined') {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	};

	onMounted(() => {
		window && window.addEventListener('scroll', () => {
			if (window.scrollY > window.innerHeight) {
				visible.value = true;
			} else {
				visible.value = false;
			}
		});
	});

</script>

<style lang="less" scoped>
</style>
